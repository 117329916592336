import founder from "../../assets/founder1.png";
import Aboutusimg from "../../assets/about-us.webp";
import { Header } from "../../components/Header/Header";
import "./Aboutus.scss";
import mission from "../../assets/mission.jpeg";
import { Footer } from "../../components/Footer/Footer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import banerjee from "../../assets/banerjee.png";
import sheena from "../../assets/sheena.png";
import kaur from "../../assets/kaur.png";
export const Aboutus = () => {
  const { pathname } = useLocation();

  // useEffect(() => {
  //   AOS.init({
  //     duration: 1000, // animation duration in milliseconds
  //     once: true, // whether animation should happen only once - while scrolling down
  //     mirror: false, // whether elements should animate out while scrolling past them
  //   });
  // }, []);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // }, [pathname]);
  return (
    <div>
      <Header />
      <div className="top-padding">
        <img className="img-fluid" src={Aboutusimg} />
      </div>
      <div className=" about-us-top m-auto">
        <div className="row container">
          <div
            className="col-lg-6 col-md-12 col-12 d-flex justify-content-center p-lg-5 p-md-5 p-0"
            data-aos="zoom-in"
          >
            <img className="image-with-shadow " src={founder} />
          </div>
          <div className="col-lg-6 col-md-12 col-12 d-flex align-items-center"               data-aos="fade-up"
              data-aos-duration="2000">
            <div>
              <div className="founder-name">
                <span>Heshan</span> Wijeratne
              </div>
              <div className="founder">Founder</div>
              <div className="founder-desc">
                In 2016, Heshan made the bold decision to move to Auckland, New
                Zealand, to pursue higher studies in the IT field. This move
                opened my eyes to the unique challenges and opportunities within
                the New Zealand healthcare sector.
                <br />
                <br />
                During my time here, I witnessed firsthand the critical need for
                skilled healthcare professionals—a gap that inspired me to make
                a meaningful difference. By recognizing the immense potential and
                talent in my home country, Sri Lanka, I embarked on a mission to
                bridge this gap. By connecting skilled Sri Lankan healthcare
                professionals with rewarding opportunities in New Zealand, I aim
                to not only address the healthcare workforce needs here but also
                provide these professionals with the chance to start a new and
                prosperous chapter in their lives.
                <br /> <br />
                I’m always open to connecting with like-minded individuals and
                organisations. Whether you’re a healthcare provider looking for
                exceptional talent or a professional seeking opportunities in
                New Zealand, I’d love to hear from you.
              </div>
            </div>
          </div>
        </div>
        {/* <div className="mission-header mt-5 pt-5">Our Team</div> */}
        {/* <div className="container">
          <div className=" row mt-5 pt-5">
            <div
              className="col-lg-8 col-md-12 col-12"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="emp1-heading">Sanskruti (San) Banerjee</div>
              <div className="emp1-heading2">Marketing Executive</div>

              <div className="emp-description pt-4">
                Kia Ora, my name is Sanskruti Banerjee. I am currently in my
                penultimate year of a Law and Commerce degree at the University
                of Auckland, majoring in both marketing alongside innovation and
                entrepreneurship. My experiences range from academic
                achievements to community leadership experience and of course,
                marketing work. I have been the marketing manager of various
                grassroots campaigns for charity organisations such as UNYOUTH,
                worked for start-ups and am experienced in design and social
                media-based platforms. Marketing excites me because of its
                potential to engage the world and as a part of Kiwi Migration, I
                hope to bridge the gap between the lack of healthcare resources
                and high-class international talent, with ease.
              </div>
            </div>
            <div
              className="col-lg-4 col-md-12 col-12 d-flex justify-content-center align-items-center"
              data-aos="zoom-in"
            >
              <img src={banerjee} className="image-with-shadow " />
            </div>
          </div>
        </div> */}
        <div className="container">
          <div className="row mt-5 pt-3">
            <div
              className="col-lg-8 col-md-12 col-12 d-flex justify-content-center align-items-center"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div>
                <div className="emp1-heading">Amolakpreet Kaur</div>
                <div className="emp1-heading2">Accountant</div>
                <div className="emp-description pt-4">
                  Amolakpreet is a dedicated and detail-oriented Accountant at
                  Kiwi Migration. With a strong background in financial
                  management and analysis, she consistently delivers accurate
                  and insightful reports, helping the company maintain financial
                  stability and growth. Known for her analytical skills and
                  meticulous nature, Amolakpreet ensures that all financial
                  records are maintained with the highest level of precision.
                  She is a valued team member who contributes to the company's
                  success with professionalism and expertise.
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-12 col-12 d-flex justify-content-center align-items-center"
              data-aos="zoom-in"
            >
              <img className="image-with-shadow " src={kaur} />
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div className="row mt-5 pt-3">
            <div
              className="col-lg-4 col-md-12 col-12 d-flex justify-content-center align-items-center order-2 order-md-1 order-lg-1"
              data-aos="zoom-in"
            >
              <img src={sheena} className="image-with-shadow " />
            </div>
            <div
              className="col-lg-8 col-md-12 col-12 d-flex justify-content-center align-items-center order-1 order-md-2 order-lg-2"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div>
                <div className="emp2-heading">Sheena Singchawala</div>
                <div className="emp2-heading2">Social Media Specialist</div>

                <div className="emp-description pt-4">
                  Sheena Singchawala is a dynamic Social Media Specialist with
                  expertise in crafting personalised marketing strategies. Known
                  for her trend awareness and data-driven approach, she excels
                  in driving engagement and growth for diverse clients.
                </div>
              </div>
            </div>
          </div>
        </div> */}


      </div>

      <div className=" about-us-bottom m-auto">
        <div className="container ">
          <div
            className="col-lg-12 col-md-12 col-12 mission-padding"
            data-aos="fade-left"
          >
            <div className="mission-header">Our Mission</div>
            <div className="mission-description">
              Kiwi Migration Ltd specializes in recruiting highly skilled
              healthcare professionals from various parts of the world to fill
              the shortage of healthcare workers across New Zealand. They
              provide nursing and care services in a professional and ethical
              manner, aiming to fulfill all skilled migration needs.
            </div>
          </div>
        </div>
      </div>

      <div className=" about-us-top2 m-auto">
        <div className="container ">
          <div
            className="col-lg-12 col-md-12 col-12 mission-padding"
            data-aos="fade-right"
          >
            <div className="mission-header">Our Vision</div>
            <div className="mission-description">
              Kiwi Migration Ltd aims to seamlessly connect overseas healthcare
              professionals with New Zealand’s healthcare institutions. They
              focus on building long-term relationships with clients and making
              a positive impact on the health and wellness of New Zealanders
              through the quality and care provided by their recruited
              professionals.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
