import "./Footer.scss";
import logo from "../../assets/logo.png";
import facebook from "../../assets/fb.png";
import linkedin from "../../assets/ln.png";
import instagram from "../../assets/insta.png";
import twitter from "../../assets/twt.png";

export const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="container footer__top">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12 footer__top-section">
              <img
                width={"60%"}
                src={logo}
                alt="kiwi-logo"
                className="footer__logo"
              />
              <p className="footer__paragraph w-75">
                we provide tailored and flexible services designed to meet your
                unique needs. Our commitment to excellence and adaptability
                ensures you receive the support you deserve.
              </p>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12 footer__top-section">
              <h3 className="footer__links_heading">Links</h3>
              <div>
                <a href="./about-us" className="footer_a_tag">
                  About Us
                </a>
              </div>
              <div>
                <a href="/contact-us" className="footer_a_tag">
                  Contact Us
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 footer__top-section">
              <h3 className="footer__links_heading">Contact us</h3>
              <p className="footer__paragraph">
                P.O Box 91885, <br /> Victoria Street West, <br /> Auckland
                1142, <br />
                New Zealand{" "}
              </p>
              <a href="tel:+64 22 478 6419" className="footer__paragraph" style={{color:'#DFA245'}}>
                +64 22 478 6419
              </a>{" "}
              <br />
              <a
                href="mailto:info@kiwimigration.co.nz"
                className="footer__paragraph " style={{color:'#DFA245'}}
              >
                info@kiwimigration.co.nz
              </a>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12 footer__top-section">
              <h3 className="footer__links_heading">Visit Us</h3>
              <div className="footer-logo-section">
                <ul className="footer-socialmedia">
                  <li className="footer-socialmedia__item">
                    <a
                      href="https://www.facebook.com/"
                      className="footer-socialmedia__item-link"
                    >
                      <img src={facebook} alt="socialmedia-logo" />
                    </a>
                  </li>
                  <li className="footer-socialmedia__item">
                    <a
                      href="https://www.instagram.com/kiwimigration/?igsh=OGNtamRwNDV4em16"
                      className="footer-socialmedia__item-link"
                    >
                      <img src={instagram} alt="socialmedia-logo" />
                    </a>
                  </li>
                  <li className="footer-socialmedia__item">
                    <a
                      href="https://twitter.com/"
                      className="footer-socialmedia__item-link"
                    >
                      <img src={twitter} alt="socialmedia-logo" />
                    </a>
                  </li>
                  <li className="footer-socialmedia__item">
                    <a
                      href="https://www.linkedin.com/company/kiwi-migrationn/"
                      className="footer-socialmedia__item-link"
                    >
                      <img src={linkedin} alt="socialmedia-logo" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        
        </div>
        <div className="container footer__horizontal_line"></div>
        <div className="footer__copyright_text">
   
          <p className="footer__copyrigh_paragraph">
            © 2024 Copyright by Kiwi Migration. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};
