import axios from "axios";
import { toast } from "react-toastify";

const api = axios.create({
  baseURL: "https://kiwi-migration-be.vercel.app/", //process.env.REACT_APP_BASE_URL,
  //baseURL: "http://localhost:3001/",
});

export const apiCall = async (url: any, method = "GET", data: any = null) => {
  const headers = {
    "Content-Type": "application/json", //'text/plain',
  };

  try {
    const response = await api.request({
      url,
      method,
      data,
      headers,
    });

    return { data: response.data, status: response.status };

    // return decrypt(response.data);
  } catch (error: any) {
    console.log(error.response.data.error);
    if (error.response && error.response.status === 401) {
      toast.error(error.response.data.error);
    }
  }
};
