import { Header } from "../../components/Header/Header";
import "./Contactus.scss";
import map from "../../assets/kiwi_map.png";
import hand from "../../assets/hand.svg";
import facebook from "../../assets/fb1.png";
import linkedin from "../../assets/ln1.png";
import instagram from "../../assets/insta1.png";
import twitter from "../../assets/twt1.png";
import whatsapp from "../../assets/whatsapp.svg";
import location from "../../assets/gps.svg";
import { Footer } from "../../components/Footer/Footer";
import MapComponent from "../../components/Map/Map";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import contactimg from "../../assets/contact-bg.png";
import { useLocation } from "react-router-dom";
import contactimg2 from "../../assets/contact-bg2.png";
import ImageUpload from "../../components/FileUpload/FileUpload";
import { sendEmailApi } from "../../services/email.service";

export const Contactus = () => {
  const [result, setResult] = useState("");
  const { pathname } = useLocation();
  const [fname, setFname] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [files, setFiles] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isReset, setIsReset] = useState(false);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  const onSubmit = async (event: any) => {
    event.preventDefault();

    let request = {
      full_name: fname,
      email: email,
      phone: phone,
      message: message,
      file: files,
    };



    if (!fname || !email || !phone || !message) {
      toast.error("please fill all fields");
    } else {
      setIsSubmitting(true)

   
      const email_resposne: any = await sendEmailApi(request);
      if(email_resposne){
        if (email_resposne.status == 200) {
          toast.success("Email sent successfully");
          setEmail("")
          setFname("")
          setFiles([])
          setMessage("")
          setPhone("")
          setIsReset(true)
        }else{
          toast.error("Error sending email")
        }
        setIsSubmitting(false)
      }

    }
    // setResult("Sending....");
    // const formData = new FormData(event.target);

    // formData.append("access_key", "0ce17483-c2a5-4256-85a5-276f2a81ddde");

    // const response = await fetch("https://api.web3forms.com/submit", {
    //   method: "POST",
    //   body: formData,
    // });

    // const data = await response.json();

    // if (data.success) {
    //   toast.success("Form Submitted Successfully");
    //   setResult("Form Submitted Successfully");
    //   event.target.reset();
    // } else {
    //   console.log("Error", data);
    //   setResult(data.message);
    //   toast.error("Error submitting form");
    // }
  };

  return (
    <div>
      <Header />

      <div className="image-container">
        <img className="img-fluid2" src={contactimg} alt="Contact" />
        <div className="overlay-text text-left">
          Reach out to Us to fulfil your dream job!
          <div className="pt-4"> We are happy to assist you</div>
        </div>
      </div>

      <section className="contactus-section">
        <div className="container">
          {/* <div className="row contactus-row">
            <div className="col-md-6 col-sm-12">
              <div className="contactus-details">
                <h1 className="contactus-details__main-title">
                  Reach out to Us to fulfil your dream job!
                  <span>
                    <img src={hand} alt="hand-img" />
                  </span>
                </h1>
                <p className="contactus-details__main-para">
                  We are available anytime <br /> to serve you .
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <img src={map} alt="world-map" className="img-fluid" />
            </div>
          </div> */}

          <div className="row pt-5">
            <div className="col-md-4 contactus-info mb-lg-0 mb-md-0 mb-5">
              <p className="contactus-info__title">Follow us</p>
              <ul className="footer-socialmedia">
                <li className="footer-socialmedia__item">
                  <a
                    href="https://www.facebook.com/"
                    className="footer-socialmedia__item-link"
                  >
                    <img src={facebook} alt="socialmedia-logo" />
                  </a>
                </li>
                <li className="footer-socialmedia__item">
                  <a
                    href="https://www.linkedin.com/company/kiwi-migrationn/"
                    className="footer-socialmedia__item-link"
                  >
                    <img src={linkedin} alt="socialmedia-logo" />
                  </a>
                </li>
                <li className="footer-socialmedia__item">
                  <a
                    href="https://www.instagram.com/kiwimigration/?igsh=OGNtamRwNDV4em16"
                    className="footer-socialmedia__item-link"
                  >
                    <img src={instagram} alt="socialmedia-logo" />
                  </a>
                </li>
                <li className="footer-socialmedia__item">
                  <a
                    href="https://twitter.com/"
                    className="footer-socialmedia__item-link"
                  >
                    <img src={twitter} alt="socialmedia-logo" />
                  </a>
                </li>
         
              </ul>
            </div>

            <div className="col-md-4 contactus-info d-flex align-items-center middle-tel mb-lg-0 mb-md-0 mb-5">
              <p className="contactus-info__details">
                <img
                  src={whatsapp}
                  alt="phone-img"
                  className="contactus-info__details-img"
                />

                <span className="contactus-info__details-text ">
                  <i className="fas fa-edit"></i> +64 224 786 419
                  <br />
                </span>
              </p>
            </div>

            <div className="col-md-4 contactus-info d-flex align-items-center justify-content-center mb-lg-0 mb-md-0 mb-5">
              <p className="contactus-info__details">
                <img
                  src={location}
                  alt="gps-img"
                  className="contactus-info__details-img"
                />
                <span className="contactus-info__details-text">
                  {" "}
                  P.O Box 91885, Victoria Street West,
                  <br /> Auckland 1142, New Zealand
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="pt-5 pb-5">
        <div className="row m-1">
          <div className="col-lg-5 col-md-5 col-12 p-5">
            <MapComponent />

            <div className="contact-end">
              "Collaboration is key. Connect with us and let's make great things
              happen together."
            </div>
            <div className="p-5">
              <img src={contactimg2} className="img-fluid" />
            </div>
          </div>
          <div className="col-lg-7 col-md-7 col-12">
            <div className="form-header">Contact KIWI Migration</div>

            <form
              id="contactus-form"
              className="contactus-form"
              onSubmit={onSubmit}
            >
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="d-flex align-items-start justify-content-start">
                    <label className="contactus-form-label">Full Name</label>
                    <span className="form-required-sign">*</span>
                  </div>
                  <input
                    type="text"
                    className="form-control contactus-form-input"
                    name="name"
                    id="name"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                    placeholder="Write your Full Name"
                  />
                  <div id="name-error" className="error-wrapper"></div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="d-flex align-items-start justify-content-start">
                    <label className="contactus-form-label">Phone Number</label>
                    <span className="form-required-sign">*</span>
                  </div>
                  <input
                    type="text"
                    className="form-control contactus-form-input"
                    name="phone"
                    id="phone"
                    placeholder="Write your Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <div id="phone-error" className="error-wrapper"></div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="d-flex align-items-start justify-content-start">
                    <label className="contactus-form-label">
                      Email Address
                    </label>
                    <span className="form-required-sign">*</span>
                  </div>
                  <input
                    type="email"
                    className="form-control contactus-form-input"
                    name="email"
                    id="email"
                    placeholder="Write your Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div id="email-error" className="error-wrapper"></div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="d-flex align-items-start justify-content-start">
                    <label className="contactus-form-label">Message</label>
                    <span className="form-required-sign">*</span>
                  </div>
                  <textarea
                    id="message"
                    name="message"
                    className="form-control contactus-form-textarea"
                    placeholder="Write your message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  <div id="message-error" className="error-wrapper"></div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <label className="contactus-form-label">Upload Your CV</label>
                  <div>
                    <ImageUpload onFilesChange={(e) => setFiles(e)} removeFile={isReset} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 d-flex justify-content-end">
                  <button className=" contact-btn" type="submit" disabled={isSubmitting}>
                    Submit
                    {isSubmitting &&
                <div className="loader ms-2"></div>}
            
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
