import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const AOSWrapper = () => {
  const location = useLocation();

  useEffect(() => {

    AOS.init({
         startEvent: 'load',
         duration: 1000,
        debounceDelay: 50, // the delay on debounce used while resizing window (advanced)


        // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
        once: false, // whether animation should happen only once - while scrolling down
        mirror: false, // whether elements should animate out while scrolling past them
    });
    // AOS.refreshHard(); // Aggressive refresh of AOS on route change

  }, [location]);

  return null;
};

export default AOSWrapper;
