import { Header } from "../../components/Header/Header";
import "./Staff.scss";
import staff from "../../assets/staff-img.png";
import home from "../../assets/s1.png";
import { services } from "../../utils/services";
import { Footer } from "../../components/Footer/Footer";
import Expandable from "../../components/expandable/expandable";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "aos/dist/aos.css";

export const LookingStaff = () => {
  // useEffect(() => {
  //   AOS.init({
  //     duration: 1000, // animation duration in milliseconds
  //     once: true, // whether animation should happen only once - while scrolling down
  //     mirror: false, // whether elements should animate out while scrolling past them
  //   });

  // }, []);

  const { pathname } = useLocation();
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth"
  //   });
  // }, [pathname]);
  return (
    <div>
      <Header />

      <div className="background-section mt-5">
        <div className="bg-slider">
          <div className="bg-slide3">
            <div className="bg-container">
              <div className="bg-slide-container">
                <div className="bg-slide-description3">
                  <div className="bg-slide-content2">
                    <h2>Looking For Staff?</h2>
                    {/* <h2>&amp;&nbsp;</h2>
                    <h2>
                      &nbsp;<span style={{ color: "#fc8459" }}></span>
                      <span style={{ color: "rgb(169 148 243)" }}>
                        {" "}
                        Recruitment
                      </span>
                    </h2> */}
                    <p>
                      We can help you with temporary or permanent staff as per
                      your requirements
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 mt-5" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12" data-aos="zoom-in">
            <img className="img-fluid" src={staff} />
          </div>
          <div className="col-lg-6 col-md-6 col-12 staff-desc">
            We recognize that each person requiring clinical support has
            distinct needs, and having the right staff is vital for delivering
            exceptional patient care. Therefore we collaborate closely with you
            to grasp your specific requirements ensuring we confidently provide
            candidates who suit your needs ideally may it be for
            temporary/permanent healthcare assistant, caregiving and nursing
            roles. <br />
            <br />
            With Kiwi Migration our clients can rest assured, knowing that their
            recruitment process is handled by industry experts. We specialize in
            delivering tailored staffing solutions, allowing you to concentrate
            on what truly matters: providing high-quality patient care.
          </div>
        </div>

        <div className="pt-5 mt-5">
          <Expandable />
        </div>

        <div className="pt-5 mt-5 mb-5">
          <div className="text-center p-5 staff-header mb-5">Our Services</div>

          <div className="row">
            {services &&
              services.map((service: any) => (
                <div
                  className="col-lg-4 col-md-4 col-12 pb-5 pe-5 mb-3"
                  data-aos="fade-left"
                >
                  <div className="row">
                    <div className="col-2">
                      <img className="img-fluid" src={service.image} />
                    </div>
                    <div className="col-10">
                      <div className="service-header">{service.title}</div>
                      <div className="service-desc">{service.description}</div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
