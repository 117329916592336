import React, { useEffect, useRef, useState } from "react";
import "./FileUpload.scss";
import doc from "../../assets/doc.png";
import { BsPaperclip } from "react-icons/bs";
import close from '../../assets/close.png';

// Define the interface for component props
interface ImageUploadProps {
  onFilesChange: (files: { file: string; name: string }[]) => void;
  removeFile:boolean;
}

// Component
const ImageUpload: React.FC<ImageUploadProps> = ({ onFilesChange,removeFile }) => {
  const [uploadedFile, setUploadedFile] = useState<{
    file: string;
    name: string;
  } | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(()=>{
    setUploadedFile(null)
  },[removeFile])

  // Handle file uploads
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      convertToBase64(file).then((base64) => {
        const newFile = {
          file: base64 as string,
          name: file.name,
        };
        setUploadedFile(newFile);
        onFilesChange([newFile]);
      });
    }
  };

  // Convert file to base64
  const convertToBase64 = (
    file: File
  ): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleLinkClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Function to get the document type based on file extension
  const getDocumentType = (fileName: string) => {
    const extension = fileName.split(".").pop()?.toLowerCase();
    switch (extension) {
      case "doc":
      case "docx":
        return "(docx)";
      case "xls":
      case "xlsx":
        return "(xlsx)";
      case "ppt":
      case "pptx":
        return "(pptx)";
      case "pdf":
        return "(pdf)";
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return "(Image)";
      default:
        return "Unknown File";
    }
  };

  return (
    <div>
      <div>
        <div className="attachment-section">
          <div className="row">
            {uploadedFile && (
              <div className="col">
                <div className="attachment d-flex align-items-center justify-content-between">
                  <div className="d-flex">
                    <div className="pe-3">
                      <img src={doc} alt="document" />
                    </div>
                    <div className="doc-name d-flex align-items-center">
                      <div>
                        {uploadedFile.name} {getDocumentType(uploadedFile.name)}
                      </div>
                    </div>
                  </div>
                  <div style={{cursor:'pointer'}} onClick={()=>setUploadedFile(null)}><img src={close}/></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {!uploadedFile && (
        <>
          {" "}
          <div className="doc-btn" onClick={handleLinkClick}>
            <BsPaperclip /> Upload Document
          </div>
          <input
            type="file"
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".pdf,.docx"
          />
        </>
      )}
    </div>
  );
};

export default ImageUpload;
