import s1 from "../assets/s1.png";
import s2 from "../assets/s2.png";
import s3 from "../assets/s3.png";
import s4 from "../assets/s4.png";
import s5 from "../assets/s5.png";
import s6 from "../assets/s6.png";
import s7 from "../assets/s7.png";
import s8 from "../assets/s8.png";
import s9 from "../assets/s9.png";

export const services = [
  {
    title: "Home Help",
    description:
      "Providing support with daily household activities to maintain a clean and organized living environment.",
    image: s1,
  },
  {
    title: "Meal Preparation",
    description:
      "Assisting with cooking and meal planning to ensure nutritious and enjoyable meals.",
    image: s2,
  },
  {
    title: "Personal Care Assistance",
    description:
      "Offering aid with personal hygiene, grooming, and other daily self-care activities.",
    image: s3,
  },
  {
    title: "Household Tasks",
    description:
      "Helping with chores such as cleaning, laundry, and other domestic responsibilities.",
    image: s4,
  },
  {
    title: "Child Care Assistance",
    description:
      "Ensuring safe and nurturing care for children, tailored to their specific needs.",
    image: s5,
  },
  {
    title: "Companionship",
    description:
      "Providing social interaction and emotional support to reduce loneliness and enhance well-being.",
    image: s6,
  },
  {
    title: "Accessing the community and driving ",
    description:
      "Supporting clients in participating in community activities and providing transportation.",
    image: s7,
  },
  {
    title: "Medication Support ",
    description:
      " Assisting with the management and administration of medications to ensure proper adherence to prescribed regimens.",
    image: s8,
  },
  {
    title: "Assistance with Shopping and attending Appointments ",
    description:
      "Helping clients with shopping errands and accompanying them to medical or personal appointments.",
    image: s9,
  },
];
