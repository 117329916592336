import React, { useEffect, useRef } from 'react';

const MapComponent = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: -36.84788014453047, lng: 174.75793202922915 },
        zoom: 15,
      });

      new window.google.maps.Marker({
        position: { lat: -36.84788014453047, lng: 174.75793202922915 },
        map,
      });
    }
  }, []);

  return <div ref={mapRef} style={{ width: '100%', height: '500px' }} />;
};

export default MapComponent;
