import "./Header.scss";
import logo from "../../assets/logo.svg";
import email from "../../assets/email1.png";
import phone from "../../assets/phone.png";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BsList, BsXLg } from "react-icons/bs";

export const Header = () => {
  const [hasShadow, setHasShadow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setHasShadow(true);
    } else {
      setHasShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="pb-2 top-header">


      <div
        className={`d-flex  position-fixed ${
          hasShadow ? "header shadow" : "header"
        }`}
      >
        <div className="d-flex ps-3 ">

          <div className="logo-container pt-lg-0 pb-lg-0 pb-2 pt-2">
            <img src={logo} className="logo-border" />
          </div>

          <div className="ps-2 hide-mobile2 align-items-center">
            <div className="d-flex align-items-center pe-3">
              <img width={30} src={email} />
            </div>
            <div className="">
              <div className="header-info">Email us</div>
              <div className="header-content">info@kiwimigration.co.nz</div>
            </div>
          </div>

          <div className="hide-mobile2 align-items-center">
            <div className="d-flex align-items-center ps-3 pe-2">
              <img width={30} src={phone} />
            </div>
            <div className="">
              <div className="header-info">Talk to us</div>
              <div className="header-content">+64 224 78 6419</div>
            </div>
          </div>
        </div>
        <div className="hide-mobile align-items-center pe-3">
          <div
            className={`menu ${location.pathname == "/" ? "active" : ""}`}
            onClick={() => navigate("/")}
          >
            Home
          </div>
          <div
            className={`menu ${
              location.pathname == "/staff" ? "active" : ""
            }`}
            onClick={() => navigate("/staff")}
          >
            Looking for Staff
          </div>
          <div
            className={`menu ${
              location.pathname == "/about-us" ? "active" : ""
            }`}
            onClick={() => navigate("/about-us")}
          >
            About Us
          </div>
          <div
            className={`menu ${
              location.pathname == "/contact-us" ? "active" : ""
            }`}
            onClick={() => navigate("/contact-us")}
          >
            Contact Us
          </div>
          <div className="hide-btn">
            <a
              className="quote-btn"
              href="https://rmb0l1xjdrz.typeform.com/to/k6I51DaU"
            >
              Request Details <span></span>
            </a>
          </div>
        </div>
      </div>
      <div className="view-mobile justify-content-around mobile-header" onClick={()=>setIsOpen(!isOpen)}>{!isOpen && <BsList style={{ fontSize: '2rem' }}/>} {isOpen && <BsXLg style={{ fontSize: '2rem' }}/>}</div>
      {/* <div className="hamburger" id="hamburger-1">
									<span className="line line-one"></span>
									<span className="line line-two"></span>
									<span className="line line-three"></span>
								</div> */}
      <nav className={`sidebar ${isOpen? 'sidebar-open':''}`}>
      <div className="menu2 pb-2" onClick={() => navigate("/")}>Home</div>
        <div  className="menu2 pb-2" onClick={() => navigate("/about-us")}>About Us</div>
        <div className="menu2 pb-2" onClick={() => navigate("/staff")}>Looking for Staff</div>
        <div className="menu2 pb-2" onClick={() => navigate("/contact-us")}>Contact Us</div>
      </nav>
    </div>
  );
};
