import { apiCall } from "../utils/api_utils";

export const sendEmailApi = async (email_data: any) => {
    try {
        const data = await apiCall('send-email/', 'POST', email_data);
        return data;
        // Process the received data
    } catch (error) {
        // Handle error here
        return error;
    }
}