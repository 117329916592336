import React, { useEffect, useState, useRef } from "react";
import { BsDashLg, BsPlusLg } from "react-icons/bs";
import "./expandable.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSpring, animated } from "@react-spring/web";
import useMeasure from "react-use-measure";

const Expandable = () => {


  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
      <div id="accordion">
        {data.map((item, index) => (
          <ExpandableCard
            key={index}
            index={index}
            activeIndex={activeIndex}
            handleToggle={handleToggle}
            title={item.title}
            content={item.content}
          />
        ))}
      </div>
    </div>
  );
};

const ExpandableCard = ({
  index,
  activeIndex,
  handleToggle,
  title,
  content,
}: any) => {
  const [ref, { height: viewHeight }] = useMeasure();
  const isOpen = activeIndex === index;

  const { height, opacity, y } = useSpring({
    from: { height: 0, opacity: 0, y: 0 },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      y: isOpen ? 0 : 100,
    },
  });

  return (
    <div className="card" data-aos="fade-up">
      <div
        className={`card-header collapse-bg${index + 1}`}
        id={`heading${index + 1}`}
      >
        <h5 className="d-flex justify-content-between">
          <div className={`collapse-bg${index + 1} pt-2 pb-2 service-title`}>
            {title}
          </div>
          <div
            onClick={() => handleToggle(index)}
            aria-expanded={isOpen}
            aria-controls={`collapse${index + 1}`}
          >
            {isOpen ? (
              <BsDashLg className="plus-ico" />
            ) : (
              <BsPlusLg className="plus-ico" />
            )}
          </div>
        </h5>
      </div>

      <animated.div
        id={`collapse${index + 1}`}
        className={`collapse ${isOpen ? "show" : ""}`}
        aria-labelledby={`heading${index + 1}`}
        data-parent="#accordion"
        style={{
          opacity,
          height: isOpen ? "auto" : height,
        }}
      >
        <animated.div ref={ref} style={{ y }}>
          <div className={`card-body collapse-bg${index + 1}`}>{content}</div>
        </animated.div>
      </animated.div>
    </div>
  );
};

const data = [
  {
    title: "Temporary Staffing",
    content:
      "At Kiwi Migration, we specialize in providing top-tier temporary healthcare staffing solutions that ensure your facility operates seamlessly. Whether you need immediate coverage due to staff shortages, seasonal demands, or special projects, our highly qualified and vetted professionals are ready to step in and deliver exceptional care. You only pay for the hours worked, offering a cost-effective solution for temporary staffing.",
  },
  {
    title: "Permanent Recruitment",
    content:
      "We at Kiwi Migration rather than inundating you with numerous CVs, we only present potential candidates who can meet your specific requirements by personally getting to know our candidates and applying a rigorous vetting process, we build strong relationships with our employees and clients to ensure that our services are aligned to your business requirements.",
  },
  {
    title: "Home Care Services",
    content:
      "Kiwi Migration is dedicated to delivering exceptional, personalized care for individuals of all ages in the comfort of their homes. We prioritize fostering independence and choice for our clients with a person-centred approach. Our goal is to deliver high-quality, consistent home care services that safeguard the dignity and privacy of our clients, ensuring they live safely and comfortably in their own homes.",
  },
];

export default Expandable;
