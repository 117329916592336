import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home/Home";
import { Aboutus } from "./pages/Aboutus/Aboutus";
import { Contactus } from "./pages/ContactUs/Contactus";
import { LookingStaff } from "./pages/Staff/Staff";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollRestoration from "./utils/scrollRestoration";
import AOSWrapper from "./utils/aosInitialize";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollRestoration />
        <AOSWrapper />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/staff" element={<LookingStaff />} />
          <Route path="/about-us" element={<Aboutus />} />

          <Route path="/contact-us" element={<Contactus />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
