import { Header } from "../../components/Header/Header";
import "./Home.scss";

import homecontact from "../../assets/home-phone.png";
import homebulb from "../../assets/home-bulb.png";
import whoweare from "../../assets/who-we-are.png";
import { Footer } from "../../components/Footer/Footer";
import care from "../../assets/care.jpeg";

import focus from "../../assets/focus.jpeg";

import value from "../../assets/value.jpeg";

import nz from "../../assets/nz-based.jpeg";
import vectorimg from "../../assets/banner_vector.png";
import check from "../../assets/Validate Account.svg";

import { BsChevronRight } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import img1 from "../../assets/bg.webp";
import img2 from "../../assets/bg41.jpg";
import img3 from "../../assets/bg51.webp";

import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel } from "react-bootstrap";

const preloadImages = (images: any) => {
  images.forEach((image: any) => {
    const img = new Image();
    img.src = image;
  });
};

export const Home = () => {
  const [bgImage, setBgImage] = useState<any>(img1);
  const [fade, setFade] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const elementsRef = useRef<(HTMLDivElement | null)[]>([]);
  const elementsRef2 = useRef<(HTMLDivElement | null)[]>([]);

  const { pathname } = useLocation();

  return (
    <div>
      <Header />

      <section className="home-banner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <Carousel fade>
                <Carousel.Item>
                  <div className="item-banner">
                    <img
                      className="d-block w-100 img"
                      src={img1}
                      alt="First slide"
                    />
                    <Carousel.Caption className="bg-slide-content">
                      <h2>Your Trusted Partner for Recruitment</h2>
                      <p>For All Your Skilled Migration Needs.</p>
                    </Carousel.Caption>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="item-banner">
                    <img
                      className="d-block w-100 img"
                      src={img2}
                      alt="First slide"
                    />
                    <Carousel.Caption className="bg-slide-content">
                      <h2>Your Trusted Partner for Recruitment</h2>
                      <p>For All Your Skilled Migration Needs.</p>
                    </Carousel.Caption>
                  </div>
                </Carousel.Item>

                <Carousel.Item>
                  <div className="item-banner">
                    <img
                      className="d-block w-100 img"
                      src={img3}
                      alt="First slide"
                    />
                    <Carousel.Caption className="bg-slide-content">
                      <h2>Your Trusted Partner for Recruitment</h2>
                      <p>For All Your Skilled Migration Needs.</p>
                    </Carousel.Caption>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="background-section mt-5">
        <div className="bg-slider">
          <div
            className={`bg-slide ${fade ? "fade-out" : ""}`}
            style={{ backgroundImage: `url(${bgImage})` }}
          >
            <div className="bg-container">
              <div className="bg-slide-container">
                <div className="bg-slide-description">
                  <div className="bg-slide-content">
                    <h2>Your Trusted Partner for Immigration & Recruitment</h2>
       
                    <p>For All Your Skilled Migration Needs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="after-background-section pt-4 pb-4">
        <div className="row ms-1 me-1">
          <div className=" hide-mobile2 col-3 align-items-center justify-content-end">
            <div className="circle me-5">
              <img className="img-border" src={homecontact} />
            </div>
          </div>
          <div className="col-lg-6 col-md-5 col-12 d-flex align-items-center">
            <div className="">
              <div className="after-section-heading">Connect with us...</div>
              <div className="after-section-description">
                We’re always ready to talk through your needs and explore how we
                can assist you further.
              </div>
            </div>
          </div>
          <div className="col-1 hide-mobile2 justify-content-end">
            {" "}
            <img className="w-75" src={homebulb} />{" "}
          </div>
          <div className="col-lg-2 col-md-2 col-12 d-flex align-items-center justify-content-center">
            <button
              onClick={() => navigate("/contact-us")}
              className="contact-us"
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>

      <div className="pt-5" data-aos="fade-up">
        <div className="row m-3">
          <div
            className="col-lg-6 col-md-12 col-12 ps-lg-5 ps-md-5 ps-0 pb-lg- pb-md-0 pb-4"
            data-aos="zoom-in"
          >
            <img className="who-we-are-img" width={"100%"} src={whoweare} />
          </div>
          <div className="col-lg-6 col-md-12 col-12 pe-lg-5 pe-md-5 pe-1">
            <div className="who-we-are-heading">Our Identity</div>
            <div className="who-we-are-desc">
              We as Kiwi Migration Ltd are specialized in healthcare staffing
              solutions tailored to meet our clients requirements. May it be
              In-Home care, Aged Care or Community Support. We take the pride of
              bringing businesses and people together to realize full potential
              by taking time to value , respect and appreciate unique qualities
              . Our greatest satisfaction comes from building long term
              relationships with people who trust us and making their aged care
              staffing process much simpler.
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="fifthsec__banner">
          <div className="fithsec__banner_content">
            <h3 className="fifthsec__banner_heading">Trust &amp; Value</h3>
            <p className="fifthsec__banner_paragraph">
              We provide the most suitable and ideal occpation to match your
              skills and proffesion. We value all your skills to position you
              well in the industry you are dreaming about. Inquire and join with
              us to lighten your future.
            </p>
          </div>
          <div className="fithsec__banner_btn_div">
            <button
              onClick={() => navigate("/contact-us")}
              className="fifthsec__banner_btn"
            >
              Apply Here
            </button>
          </div>
          <div className="fifthsec__banner_vector">
            <img src={vectorimg} alt="vector-graphic" />
          </div>
        </div>
      </div>

      <div className="container pt-5">
        <div className="row m-auto scroll-element" data-aos="fade-right">
          <div className="col-lg-6 col-md-6 col-12 ps-lg-5 ps-md-5 ps-1 ">
            <div className="why-choose-header">Care</div>
            <div className="why-choose-desc">
              At Kiwi Migration, we prioritize care as the cornerstone of our
              success, aiming to build long-term relationships with our clients.
              Our focus is on seamlessly connecting overseas healthcare
              professionals with New Zealand’s healthcare institutions. We
              understand the importance of personalized support and are
              committed to ensuring a smooth transition for healthcare workers,
              helping them navigate the complexities of relocation and
              integration into the New Zealand healthcare system. This
              commitment to care fosters trust and reliability in our services,
              ensuring both client satisfaction and successful placements.
            </div>

            {/* <div>
              <a
                className="mt-4 choose-btn"
                href="https://vkvc68vc4i9.typeform.com/to/UjcRSmWz"
              >
                Inquire Details{" "}
                <span className="ps-2">
                  <BsChevronRight />
                </span>
              </a>
            </div> */}
          </div>
          <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center pt-lg-0 pt-md-0 pt-4">
            <img className="care" src={care} />
          </div>
        </div>

        <div className="row m-auto mt-5 scroll-element2" data-aos="fade-left">
          <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center order-lg-0 order-1 pt-lg-0 pt-md-0 pt-4">
            <img className="care " src={focus} />
          </div>
          <div className="col-lg-6 col-md-6 col-12 pe-lg-5 pe-md-5 pe-1 order-lg-1 order-0">
            <div className="why-choose-header pt-lg-2 pt-0">Focus</div>
            <div className="why-choose-desc">
              At Kiwi Migration, our focus is on thoroughly understanding our
              clients businesses, values, and requirements. We take pride in
              connecting quality healthcare professionals—including Health Care
              Assistants, Registered Nurses, Doctors, and Aged Health Care
              Assistants—with New Zealand's healthcare institutions. Our goal is
              to make a significant positive impact on the health and wellness
              of New Zealanders by ensuring that only the most qualified and
              dedicated professionals are placed where they are needed most.
            </div>
          </div>
        </div>

        <div
          className="row m-auto mt-5 pt-3 scroll-element"
          data-aos="fade-right"
        >
          <div className="col-lg-6 col-md-6 col-12 ps-lg-5 ps-md-5 ps-1 order-lg-0 order-0">
            <div className="why-choose-header pt-lg-2 pt-0">Value</div>
            <div className="why-choose-desc">
              We highly value our clients and employees, fostering a supportive
              and collaborative environment. Our dedicated recruitment
              consultants work closely with you to align your skills,
              preferences, and career goals with the ideal healthcare role.
              Leveraging our extensive network and expertise, we facilitate a
              smooth transition into your new position, ensuring both personal
              and professional success. Our commitment to valuing people is
              fundamental to building strong, lasting relationships and
              delivering exceptional service.
            </div>

            {/* <div>
              <a
                className="mt-4 choose-btn"
                href="https://vkvc68vc4i9.typeform.com/to/UjcRSmWz"
              >
                Inquire Details{" "}
                <span className="ps-2">
                  <BsChevronRight />
                </span>
              </a>
            </div> */}
          </div>
          <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center order-lg-1 order-1 pt-lg-0 pt-md-0 pt-4">
            <img className="care" src={value} />
          </div>
        </div>

        <div className="row m-auto mt-5 scroll-element2" data-aos="fade-left">
          <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center order-lg-0 order-1 pt-lg-0 pt-md-0 pt-4">
            <img className="care " src={nz} />
          </div>
          <div className="col-lg-6 col-md-6 col-12 pe-lg-5 pe-md-5 pe-1 order-lg-1 order-0">
            <div className="why-choose-header pt-lg-2 pt-0">
              New Zealand Based
            </div>
            <div className="why-choose-desc">
              We possess an in-depth understanding of New Zealand culture and
              excel in helping diverse candidates thrive here. Our strong
              connections within migrant communities enable us to offer
              personalized support and networking opportunities, ensuring our
              candidates integrate smoothly into Kiwi life. By leveraging our
              local expertise and relationships, we facilitate a welcoming and
              supportive environment for newcomers, aiding in their successful
              settlement and professional growth in New Zealand.
            </div>
          </div>
        </div>
      </div>

      <section className="setup-section mt-5">
        <div className="wrapper">
          <div className="image_bac">
            <div className="row m-auto">
              <div className="col-md-6"></div>
              <div className="col-md-6 right_setup">
                <h2 className="secondsec__heading">Our Services.</h2>
                <h2 className="secondsec__heading_small_text">
                  We specialize in recruiting highly skilled healthcare
                  professionals from various parts of the world to fill the
                  shortage of healthcare workers across various parts of NZ. We
                  provide nursing and care services in a professional and
                  ethical manner
                </h2>

                <div
                  className="secondsec__step scroll-element2"
                  data-aos="fade-left"
                >
                  <img src={check} alt="add-user-info-icon" />

                  <p className="secondsec__paragraph_text">
                    Thorough Background checks{" "}
                  </p>
                </div>

                <div
                  className="secondsec__step scroll-element2"
                  data-aos="fade-left"
                >
                  <img src={check} alt="add-user-info-icon" />
                  <p className="secondsec__paragraph_text">
                    Visa application process
                    {/* <span className="secondsec__paragraph_text--sub">
                      {" "}
                      Use email to send all your business documents
                    </span> */}
                  </p>
                </div>

                <div
                  className="secondsec__step scroll-element2"
                  data-aos="fade-left"
                >
                  <img src={check} alt="add-user-info-icon" />
                  <p className="secondsec__paragraph_text">
                    Time and cost efficiency
                  </p>
                </div>

                <div
                  className="secondsec__step scroll-element2"
                  data-aos="fade-left"
                >
                  <img src={check} alt="add-user-info-icon" />
                  <p className="secondsec__paragraph_text">
                    Dedicated support
                    {/* <span className="secondsec__paragraph_text--sub">
                      The basic configuration is complete.
                    </span> */}
                  </p>
                </div>

                <div
                  className="secondsec__step scroll-element2"
                  data-aos="fade-left"
                >
                  <img src={check} alt="add-user-info-icon" />
                  <p className="secondsec__paragraph_text">
                    Regular progress updates
                    {/* <span className="secondsec__paragraph_text--sub">
                      The basic configuration is complete.
                    </span> */}
                  </p>
                </div>

                <div
                  className="secondsec__step scroll-element2"
                  data-aos="fade-left"
                >
                  <img src={check} alt="add-user-info-icon" />
                  <p className="secondsec__paragraph_text">
                    Accommodation assistance
                    {/* <span className="secondsec__paragraph_text--sub">
                      The basic configuration is complete.
                    </span> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
